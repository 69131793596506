import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import {
	LIST_FILTER_RULE_TYPES,
	LIST_INPUT_TYPE,
	LIST_ALLOCATION_FILTER_FUNCTIONS,
} from 'constants/studyDesign'
import { useActiveStudyQuery } from 'hooks/useActiveStudyQuery'
import { findSelectedList } from 'helpers/visualFlowModules/findSelectedList'
import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import Icon from 'components/_scaffolding/Icon'
import Select from 'components/_formik/_base/Select'
import SelectAction from 'components/_scaffolding/_input/SelectAction'

import AllocationRankingQuestionRule from './_components/AllocationRankingQuestionRule'
import CommunityRule from './_components/CommunityRule'
import FlowQuestionRule from './_components/FlowQuestionRule'
import ItemAttributeRule from './_components/ItemAttributeRule'
import MatrixListRule from './_components/MatrixListRule'

import classes from './ListFilterSettings.module.scss'

const getCommunityRule = () => ({
	ruleType: LIST_FILTER_RULE_TYPES.COMMUNITY,
	listKey: '',
	communityKey: '',
	is: true,
	function: CONDITION_SELECTION_TYPES.EQUAL_TO,
	includeNoData: false,
})

const getMatrixRule = () => ({
	ruleType: LIST_FILTER_RULE_TYPES.MATRIX,
	idMatrix: '',
	is: true,
	function: CONDITION_SELECTION_TYPES.ANY_OF,
	options: [],
	includeNoData: false,
})

const getQuestionRule = () => ({
	ruleType: LIST_FILTER_RULE_TYPES.QUESTION,
	listKey: '',
	idModule: '',
	is: true,
	function: CONDITION_SELECTION_TYPES.CONTAINS,
	includeNoData: false,
})

const getItemAttributeRule = () => ({
	ruleType: LIST_FILTER_RULE_TYPES.ITEM_ATTRIBUTE,
	listKey: '',
	is: true,
	function: null,
	value: '',
	valueArray: [''],
	includeNoData: false,
})

const getOptionsWithNumberValueRule = ruleType => ({
	ruleType,
	listKey: '',
	idModule: '',
	is: true,
	function: LIST_ALLOCATION_FILTER_FUNCTIONS.MAX,
	includeNoData: false,
	value: '',
})

const ListFilterSettings = props => {
	const intl = useIntl()

	const { study } = useActiveStudyQuery()

	if (study === null) {
		return null
	}

	const selectedList =
		props.values.inputType === LIST_INPUT_TYPE.FILE
			? { definition: props.values }
			: findSelectedList(props.values.idInputList, props.upperLists)

	if (selectedList === null || selectedList.definition.items.length === 0) {
		return null
	}

	const deleteRule = index => {
		const newRules = [...props.values.filterRules]

		newRules.splice(index, 1)

		props.setFieldValue('filterRules', newRules)
	}

	const getRuleLabelTranslation = rule => {
		if (rule.ruleType === LIST_FILTER_RULE_TYPES.MATRIX) {
			return 'list_filter.detail.rule.matrix'
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.COMMUNITY) {
			return 'list_filter.detail.rule.community'
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.QUESTION) {
			return 'list_filter.detail.rule.question'
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.ITEM_ATTRIBUTE) {
			return 'list_filter.detail.rule.item_attribute'
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION) {
			return 'list_filter.detail.rule.allocation.title'
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.RANKING_QUESTION) {
			return 'list_filter.detail.rule.ranking.title'
		}

		throw new Error(`invalid ruleType: ${rule.ruleType}`)
	}

	const renderRuleComponent = (rule, ruleIndex) => {
		if (rule.ruleType === LIST_FILTER_RULE_TYPES.MATRIX) {
			return (
				<MatrixListRule
					activeLanguage={props.activeLanguage}
					disabled={props.disabled}
					idInputList={props.values.idInputList}
					rule={rule}
					ruleIndex={ruleIndex}
					setFieldValue={props.setFieldValue}
					upperMatrixModules={props.upperMatrixModules}
				/>
			)
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.COMMUNITY) {
			return (
				<CommunityRule
					disabled={props.disabled}
					importedModules={study.importedModules}
					rule={rule}
					ruleIndex={ruleIndex}
					selectedList={selectedList}
				/>
			)
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.QUESTION) {
			return (
				<FlowQuestionRule
					activeLanguage={props.activeLanguage}
					disabled={props.disabled}
					languages={props.languages}
					upperChoices={props.upperChoices}
					rule={rule}
					ruleIndex={ruleIndex}
					selectedList={selectedList}
				/>
			)
		}

		if (
			rule.ruleType === LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION ||
			rule.ruleType === LIST_FILTER_RULE_TYPES.RANKING_QUESTION
		) {
			return (
				<AllocationRankingQuestionRule
					disabled={props.disabled}
					upperAllocations={props.upperAllocations}
					upperRankingQuestions={props.upperRankingQuestions}
					rule={rule}
					ruleIndex={ruleIndex}
					selectedList={selectedList}
				/>
			)
		}

		if (rule.ruleType === LIST_FILTER_RULE_TYPES.ITEM_ATTRIBUTE) {
			return (
				<ItemAttributeRule
					disabled={props.disabled}
					rule={rule}
					ruleIndex={ruleIndex}
					selectedList={selectedList}
					setFieldValue={props.setFieldValue}
				/>
			)
		}

		throw new Error(`invalid ruleType: ${rule.ruleType}`)
	}

	const renderRule = (rule, ruleIndex) => {
		const idLabelTranslation = getRuleLabelTranslation(rule)

		return (
			<div
				className={classnames(classes.rule, {
					[classes['rule--first']]: ruleIndex === 0,
				})}
				key={ruleIndex}
			>
				<div className={classes.rule__label}>{intl.formatMessage({ id: idLabelTranslation })}</div>
				{renderRuleComponent(rule, ruleIndex)}
				<div
					className={classnames(classes.rule__delete, {
						[classes['rule__delete--first']]: ruleIndex === 0,
					})}
					onClick={() => deleteRule(ruleIndex)}
				>
					<Icon name={Icon.NAMES.X_CLOSE} />
				</div>
			</div>
		)
	}

	const addRule = newRule => {
		props.setFieldValue('filterRules', [...props.values.filterRules, newRule])
	}

	const addCommunityRule = () => {
		addRule(getCommunityRule())
	}

	const addMatrixRule = () => {
		addRule(getMatrixRule())
	}

	const addQuestionRule = () => {
		addRule(getQuestionRule())
	}

	const addItemAttributeRule = () => {
		addRule(getItemAttributeRule())
	}

	const addAllocationQuestionRule = () => {
		addRule(getOptionsWithNumberValueRule(LIST_FILTER_RULE_TYPES.ALLOCATION_QUESTION))
	}

	const addRankingQuestionRule = () => {
		addRule(getOptionsWithNumberValueRule(LIST_FILTER_RULE_TYPES.RANKING_QUESTION))
	}

	const addRuleOptions = [
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.community' }),
			value: addCommunityRule,
		},
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.matrix' }),
			value: addMatrixRule,
		},
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.question' }),
			value: addQuestionRule,
		},
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.allocation_question' }),
			value: addAllocationQuestionRule,
		},
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.ranking_question' }),
			value: addRankingQuestionRule,
		},
		{
			label: intl.formatMessage({ id: 'list_filter.detail.add.item_attribute' }),
			value: addItemAttributeRule,
		},
	]

	const handleAddRuleChange = option => {
		option.value()
	}

	const filterOperatorOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'condition_builder.all_apply' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'condition_builder.one_applies' }),
		},
	]

	return (
		<div>
			{props.values.filterRules.length === 0 && (
				<div className="title-info">
					{intl.formatMessage({ id: 'list_filter.detail.rules.empty' })}
				</div>
			)}
			{props.values.filterRules.length > 0 && (
				<Field
					component={Select}
					disabled={props.disabled}
					name={'isFilterEveryOf'}
					options={filterOperatorOptions}
				/>
			)}
			{props.values.filterRules.map(renderRule)}
			{props.disabled === false && (
				<div className={classes.add}>
					<SelectAction
						iconNameOverride={Icon.NAMES.ADD}
						id="list-filter-add-rule"
						onChange={handleAddRuleChange}
						options={addRuleOptions}
						label={intl.formatMessage({ id: 'list_filter.detail.add_filter_rule' })}
					/>
				</div>
			)}
		</div>
	)
}

ListFilterSettings.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	setFieldValue: PropTypes.func.isRequired,
	upperAllocations: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperChoices: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperLists: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperMatrixModules: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	upperRankingQuestions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	values: PropTypes.shape({
		filterRules: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	}).isRequired,
}

export default ListFilterSettings
