import { sanitizeUUID } from 'helpers/gql/sanitizeUUID'

/**
 * @param {string} idStudy
 * @param {string} idStudyObject
 * @param {string} blobType
 * @param {string | null} idSegment
 * @param {string | null} idChoiceQuestion
 */
export const getBlobDataKey = (
	idStudy,
	idStudyObject,
	blobType,
	idSegment = null,
	idChoiceQuestion = null,
) => `${idStudy}/${idStudyObject}/${blobType}/${idSegment}/${idChoiceQuestion}`

/**
 * @param {string} idStudyObject
 * @param {string} idSegment
 * @param {string} blobType
 * @param {string | null} idChoiceQuestion
 */
export const getBlobDataGraphQLKey = (
	idStudyObject,
	idSegment,
	blobType,
	idChoiceQuestion = null,
) => {
	// http://spec.graphql.org/June2018/#sec-Names
	const sanitizedIdStudyObject = sanitizeUUID(idStudyObject)
	const sanitizedIdSegment = sanitizeUUID(idSegment)
	const sanitizedBlobType = sanitizeUUID(blobType)
	const sanitizedIdChoiceQuestion = sanitizeUUID(idChoiceQuestion)

	const baseKey = `study_object_${sanitizedIdStudyObject}_segment_${sanitizedIdSegment}_blob_type_${sanitizedBlobType}`

	return sanitizedIdChoiceQuestion === null ? baseKey : `${baseKey}_${sanitizedIdChoiceQuestion}`
}
