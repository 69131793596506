import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from 'react-tooltip'
import classnames from 'classnames'

import { COLORS } from 'constants/colors'

import classes from './Tooltip.module.scss'

const DefaultTooltip = ({
	backgroundColor = COLORS.GREY_45,
	className = null,
	id,
	...restOfProps
}) => (
	<Tooltip
		backgroundColor={backgroundColor}
		textColor={COLORS.WHITE}
		className={classnames(classes.tooltip, {
			[className]: className !== null,
		})}
		id={id}
		{...restOfProps}
	/>
)

DefaultTooltip.propTypes = {
	id: PropTypes.string.isRequired,
	backgroundColor: PropTypes.string,
	className: PropTypes.string,
}

export default DefaultTooltip
