import PropTypes from 'prop-types'
import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { orderBy as naturalOrderBy } from 'natural-orderby'
import _ from 'lodash'

import { useContactUsForm } from 'hooks/useContactUsForm'

import IncludedStatements from 'components/_formik/_complex/IncludedStatements'

import { Query } from '@apollo/client/react/components'
import { GET_STUDY_OBJECT_DATA } from 'gql/queries/study'

import classes from './StatementsMultiselect.module.scss'

const StatementsMultiselect = props => {
	const { index, idStudy, condition } = props

	const intl = useIntl()

	const [ContactUs, showContactUs] = useContactUsForm()

	const getOptionShape = statement => ({
		label: statement.label,
		value: statement.idStatement || statement.idStatementGroup,
	})

	const getMultiSelectOptions = statements => {
		const multiSelectOptions = statements.map(getOptionShape)

		return naturalOrderBy(multiSelectOptions, [option => option.label], ['asc'])
	}

	const getStatementsValue = (statementOptions, idsStatements) =>
		statementOptions.filter(option => idsStatements.includes(option.value) === true)

	return (
		<Query {...GET_STUDY_OBJECT_DATA(idStudy, condition.studyObject.id)}>
			{({ data, error, loading, refetch }) => {
				if (error !== undefined)
					return (
						<p className="title-error">
							<FormattedMessage
								id="multiselect.cannot_load_statements"
								values={{
									clickHere: (
										<span onClick={() => refetch()} className={classes.clickable}>
											{intl.formatMessage({ id: 'click_here' })}
										</span>
									),
									contactUs: (
										<span className={classes.clickable} onClick={showContactUs}>
											{intl.formatMessage({ id: 'contact_us' })}
										</span>
									),
								}}
							/>
							<ContactUs />
						</p>
					)

				const { statements, statementGroups } = _.get(data, 'study.studyObjectData', {
					statements: [],
					statementGroups: [],
				})
				const filteredStatements = statements
					.filter(statement => statement.isInvalid === false)
					.concat(statementGroups)

				return (
					<div>
						<IncludedStatements
							disabled={props.disabled}
							fieldName={`conditions[${index}].left.idsStatements`}
							isLoading={loading}
							label={{ showLabel: false, errorLabel: intl.formatMessage({ id: 'statements' }) }}
							maxHeight={75}
							onChange={props.onChange}
							options={getMultiSelectOptions(filteredStatements)}
							value={getStatementsValue(
								getMultiSelectOptions(filteredStatements),
								condition.left.idsStatements,
							)}
						/>

						{condition.left.idsStatements.length === 0 && (
							<span className="title-error">
								{intl.formatMessage({ id: 'multiselect.no_statement_selected' })}
							</span>
						)}
					</div>
				)
			}}
		</Query>
	)
}

StatementsMultiselect.propTypes = {
	condition: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	idStudy: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default StatementsMultiselect
