import { enTranslator as intl } from 'intl.js'

import { VISUAL_FLOW_MODULE_TYPES, REDIRECT_SUBTYPE } from 'constants/studyDesign'
import getDatasetShortName from 'routes/_study/StudyDesign/_store/helpers/getDatasetShortName'
import { getLanguagesLabelsObject } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getLanguagesLabelsObject.js'

import Redirect from 'routes/_study/StudyDesign/Detail/FlowDetail/_details/Redirect'

export default {
	type: VISUAL_FLOW_MODULE_TYPES.REDIRECT,
	style: 'list',
	title: () => 'redirect.title',
	description: () => 'redirect.description',
	component: Redirect,
	generator: (modules, additionalProperties) => ({
		type: VISUAL_FLOW_MODULE_TYPES.REDIRECT,
		shortName: getDatasetShortName(
			modules,
			intl.formatMessage({ id: 'redirect.title' }),
			VISUAL_FLOW_MODULE_TYPES.REDIRECT,
		),
		redirectUrl: 'http://',
		// unused in v1
		subtype: REDIRECT_SUBTYPE.AUTO_REDIRECT,
		redirectLabel: getLanguagesLabelsObject(additionalProperties.languages, 'proceed'),
	}),
}
