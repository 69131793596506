import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Formik, Form } from 'formik'
import { useIntl, FormattedMessage } from 'react-intl'

import { matchUrl } from 'components/_formik/validation'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'
import { useActiveStudyQuery } from 'hooks/useActiveStudyQuery'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import UrlInput from 'components/_formik/_complex/UrlInput'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import CopyableString from 'components/_scaffolding/CopyableString'

import classes from './Redirect.module.scss'

const Redirect = props => {
	const intl = useIntl()

	const { activeLanguage, disabled } = props

	const { study } = useActiveStudyQuery()

	if (study === null) {
		return null
	}

	const returnUrl = `${study.solverUrl}/solve/${study.idStudy}`

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values }) => (
				<Fragment>
					<Header
						activeLanguage={activeLanguage}
						closeModuleDetail={props.closeModuleDetail}
						copyModule={props.copyModule}
						copyModuleButtonParams={props.copyModuleButtonParams}
						disabled={disabled}
						generalDefinition={props.generalDefinition}
						isFlowChanged={props.isFlowChanged}
						isRelatedModuleInvalid={props.isRelatedModuleInvalid}
						languages={props.languages}
						languagesValidationResult={props.languagesValidationResult}
						moduleDefinition={values}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						setActiveLanguage={props.setActiveLanguage}
						showCopyToLibrary
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={() => (
								<Form>
									<DatasetShortName
										disabled={disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									<UrlInput
										disabled={props.disabled}
										fieldName="redirectUrl"
										label={intl.formatMessage({ id: 'endstudy.redirect_url' })}
										placeholder={intl.formatMessage({ id: 'endstudy.type_url' })}
										value={values.redirectUrl}
									/>
									{matchUrl(values.redirectUrl) === true && (
										<span className="title-error">
											{intl.formatMessage({ id: 'redirect.invalid_url' })}
										</span>
									)}
									<div className="title-info">
										<FormattedMessage
											id="redirect.redirect_back"
											values={{
												code: string => <code>{string}</code>,
												url: (
													<CopyableString
														className={classes['solver-link']}
														fontSize={12}
														stringToDisplay={returnUrl}
														linkToCopy={returnUrl}
														whiteSpace="wrap"
													/>
												),
											}}
										/>
									</div>
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

Redirect.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	copyModule: PropTypes.func.isRequired,
	copyModuleButtonParams: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isRelatedModuleInvalid: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	languagesValidationResult: PropTypes.object.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	saveModule: PropTypes.func.isRequired,
	setActiveLanguage: PropTypes.func.isRequired,
}

export default Redirect
