import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { useIntl } from 'react-intl'

import { hasInvalidRedirect } from 'routes/_study/StudyDesign/_store/helpers/flowValidation/validateModule/validateEndstudy'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import AutoSubmit from 'components/_formik/_complex/AutoSubmit'
import UrlInput from 'components/_formik/_complex/UrlInput'
import Checkbox from 'components/_formik/_base/Checkbox'
import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import DatasetShortName from 'components/_formik/_custom/_studyDesign/DatasetShortName'
import Header from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/Header'
import FormHolder from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/FormHolder'
import ModuleAdvancedOptionsToggle from 'routes/_study/StudyDesign/Detail/FlowDetail/_components/ModuleAdvancedOptionsToggle'

import PanelRedirects from './_components/PanelRedirects'

const getInitialIsExpanded = initialValues =>
	initialValues.enableRedirectButton === true || initialValues.overrideRedirect === true

const EndStudy = props => {
	const intl = useIntl()

	const [isExpanded, setIsExpanded] = useState(getInitialIsExpanded(props.initialValues))

	const handleIsExpandedToggleClick = () =>
		setIsExpanded(currentIsExpanded => currentIsExpanded === false)

	return (
		<Formik initialValues={{ ...props.initialValues }} onSubmit={() => {}}>
			{({ values }) => (
				<Fragment>
					<Header
						activeLanguage={props.activeLanguage}
						closeModuleDetail={props.closeModuleDetail}
						generalDefinition={props.generalDefinition}
						disabled={props.disabled}
						moduleDefinition={values}
						isFlowChanged={props.isFlowChanged}
						languages={props.languages}
						languagesValidationResult={props.languagesValidationResult}
						openCopyToLibraryForm={props.openCopyToLibraryForm}
						setActiveLanguage={props.setActiveLanguage}
						showCopyToLibrary={false}
					/>
					<FormHolder>
						<AutoSubmit
							values={values}
							onSave={props.saveModule}
							formComponent={isSubmitting => (
								<Form>
									<DatasetShortName
										disabled={props.disabled}
										errorInvalidSimpleName={props.errorInvalidSimpleName}
										errorNonUniqueSimpleName={props.errorNonUniqueSimpleName}
										values={values}
									/>
									<Label
										label={intl.formatMessage({
											id: 'panel_redirects',
										})}
									/>
									<PanelRedirects
										isInternalEmployee={props.isInternalEmployee}
										respondentSources={props.respondentSources}
										subtype={props.initialValues.subtype}
									/>
									<ModuleAdvancedOptionsToggle
										id="end-study-advanced-options-toggle"
										onClick={handleIsExpandedToggleClick}
										isExpanded={isExpanded}
									/>
									{isExpanded === true && (
										<div data-testid="advanced-options">
											<Field
												component={Checkbox}
												componentProps={{
													label: intl.formatMessage({
														id: 'endstudy.redirect_button.enable',
													}),
												}}
												disabled={props.disabled}
												name="enableRedirectButton"
											/>
											{values.enableRedirectButton === true && (
												<div data-testid="redirect-button">
													<Label
														label={intl.formatMessage({
															id: 'endstudy.redirect_button.label',
														})}
													/>
													<Field
														component={Input}
														disabled={props.disabled}
														name={`redirectLabel.${props.activeLanguage}`}
													/>
													{values.redirectLabel[props.activeLanguage].length === 0 && (
														<span className="title-error">
															{intl.formatMessage({
																id: 'endstudy.redirect_button_label.required',
															})}
														</span>
													)}
													{values.redirectLabel[props.activeLanguage].length > 50 && (
														<span className="title-error">
															{intl.formatMessage({
																id: 'endstudy.redirect_button_label.too_long',
															})}
														</span>
													)}
												</div>
											)}
											{props.shouldShowAdvancedFeatures === true && (
												<Fragment>
													<Field
														component={Checkbox}
														componentProps={{
															label: intl.formatMessage({ id: 'endstudy.redirect.override' }),
														}}
														disabled={props.disabled}
														name="overrideRedirect"
													/>
													{values.overrideRedirect === true && (
														<div data-testid="override-redirect">
															<UrlInput
																disabled={props.disabled}
																fieldName="redirectUrl"
																label={intl.formatMessage({ id: 'endstudy.redirect_url' })}
																placeholder={intl.formatMessage({ id: 'endstudy.type_url' })}
																value={values.redirectUrl}
															/>

															{hasInvalidRedirect({ definition: values }) === true && (
																<span className="title-error">
																	{intl.formatMessage({ id: 'endstudy.invalidUrl' })}
																</span>
															)}
														</div>
													)}
												</Fragment>
											)}
										</div>
									)}
								</Form>
							)}
						/>
					</FormHolder>
				</Fragment>
			)}
		</Formik>
	)
}

EndStudy.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	closeModuleDetail: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	errorInvalidSimpleName: PropTypes.bool.isRequired,
	errorNonUniqueSimpleName: PropTypes.bool.isRequired,
	generalDefinition: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isFlowChanged: PropTypes.bool.isRequired,
	isInternalEmployee: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	languagesValidationResult: PropTypes.object.isRequired,
	openCopyToLibraryForm: PropTypes.func.isRequired,
	respondentSources: PropTypes.array.isRequired,
	saveModule: PropTypes.func.isRequired,
	setActiveLanguage: PropTypes.func.isRequired,
	shouldShowAdvancedFeatures: PropTypes.bool.isRequired,
}

export default EndStudy
