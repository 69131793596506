import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Label from 'components/_formik/_base/Label'
import Select from 'components/_formik/_base/Select'
import ListFilterIncludeNoData from 'components/_formik/_complex/StudyDesign/ListFilterIncludeNoData'

import { CONDITION_SELECTION_TYPES } from 'constants/conditionBuilder'
import { studyLanguagesShape } from 'constants/languages/studyLanguagesShape'

import classes from './FlowQuestionRule.module.scss'

const getMismatchedChoiceOptions = (
	module,
	selectedListItems,
	selectedListColumn,
	activeLanguage,
	languages,
) => {
	if (module === undefined) {
		return []
	}

	if (selectedListItems.length === 0) {
		return []
	}

	if (selectedListColumn === undefined) {
		return []
	}

	const listItemLabels = selectedListItems.map(item => item[selectedListColumn?.key])

	return module.definition.options
		.filter(
			option =>
				option.isHidden !== true &&
				option.isNoneOfThese === false &&
				listItemLabels.every(label =>
					languages.every(({ language }) => label !== option.label[language]),
				),
		)
		.map(option => option.label[activeLanguage])
}

const FlowQuestionRule = props => {
	const intl = useIntl()

	const { ruleIndex } = props

	const flowModuleOptions = props.upperChoices.map(module => ({
		value: module.definition.id,
		label: module.definition.shortName,
	}))

	const selectedListColumnOptions =
		props.selectedList?.definition.columns.map(column => ({
			label: column.key,
			value: column.key,
		})) ?? []

	const negationSelectOptions = [
		{
			value: true,
			label: intl.formatMessage({ id: 'is' }),
		},
		{
			value: false,
			label: intl.formatMessage({ id: 'is_not' }),
		},
	]

	const selectedModule = props.upperChoices.find(
		module => module.definition.id === props.rule.idModule,
	)

	const selectedListColumn = props.selectedList?.definition.columns.find(
		column => column.key === props.rule.listKey,
	)

	const selectedListItems = props.selectedList?.definition.items ?? []

	const mismatchedChoiceOptions = getMismatchedChoiceOptions(
		selectedModule,
		selectedListItems,
		selectedListColumn,
		props.activeLanguage,
		props.languages,
	)

	const isQuestionFromSameList =
		selectedModule?.definition.dynamicOptionsSettings?.isActive === true &&
		props.selectedList?.definition.id ===
			selectedModule?.definition.dynamicOptionsSettings?.idMainList

	return (
		<div id={`rule-${ruleIndex}`}>
			<Label
				label={intl.formatMessage({
					id: 'list_filter.detail.rule.question.question',
				})}
			/>
			<Field
				component={Select}
				disabled={props.disabled}
				name={`filterRules[${ruleIndex}].idModule`}
				options={flowModuleOptions}
			/>
			{props.rule.idModule === '' && (
				<div className="title-info">
					{intl.formatMessage({ id: 'list_filter.detail.rule.question.select_question' })}
				</div>
			)}
			{props.rule.idModule !== '' && selectedModule === undefined && (
				<div className="title-error">
					{intl.formatMessage({ id: 'list_filter.detail.rule.question.missing_question' })}
				</div>
			)}
			<Label label={intl.formatMessage({ id: 'comparison_rule' })} />
			<div className={classes.split}>
				<Field
					component={Select}
					disabled={props.disabled}
					name={`filterRules[${ruleIndex}].is`}
					options={negationSelectOptions}
				/>
				<div className={classes.contains}>
					{intl.formatMessage({ id: CONDITION_SELECTION_TYPES.CONTAINS }).toLowerCase()}
				</div>
			</div>
			{isQuestionFromSameList === false && (
				<Fragment>
					<Label
						label={intl.formatMessage({ id: 'list_filter.detail.rule.community.list_item_param' })}
					/>
					<Field
						component={Select}
						disabled={props.disabled}
						name={`filterRules[${ruleIndex}].listKey`}
						options={selectedListColumnOptions}
					/>
					{props.rule.listKey === '' && (
						<div className="title-info">
							{intl.formatMessage({ id: 'list_filter.detail.rule.community.select_list_key' })}
						</div>
					)}
					{props.rule.listKey !== '' && selectedListColumn === undefined && (
						<div className="title-error">
							{intl.formatMessage({ id: 'list_filter.detail.rule.community.missing_list_key' })}
						</div>
					)}
					{mismatchedChoiceOptions.length > 0 && (
						<div className="title-error">
							{intl.formatMessage(
								{ id: 'list_filter.detail.rule.question.options_mismatch' },
								{ mismatchedOptions: mismatchedChoiceOptions.join(', ') },
							)}
						</div>
					)}
				</Fragment>
			)}
			<ListFilterIncludeNoData
				disabled={props.disabled}
				ruleIndex={ruleIndex}
				ruleType={props.rule.ruleType}
			/>
		</div>
	)
}

FlowQuestionRule.propTypes = {
	activeLanguage: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	languages: studyLanguagesShape.isRequired,
	upperChoices: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
	rule: PropTypes.object.isRequired,
	ruleIndex: PropTypes.number.isRequired,
	selectedList: PropTypes.object.isRequired,
}

export default FlowQuestionRule
