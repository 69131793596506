import { VISUAL_FLOW_MODULE_TYPES, PROFLOW_TYPES } from 'constants/studyDesign'

const canBeUiCommandUsedInCondition = module =>
	module.definition.enableConditions === true &&
	module.definition.loopSettings.isActive === false &&
	module.definition.isRandomizer === false &&
	(module.definition.isBlock === false ||
		(module.definition.isBlock === true && module.definition.isTrack === true))

const canBeUsedInCondition = module => {
	const allowedTypes = [
		VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
		VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT,
		VISUAL_FLOW_MODULE_TYPES.A_IMAGE_CHOICE,
		VISUAL_FLOW_MODULE_TYPES.A_OEQ,
		VISUAL_FLOW_MODULE_TYPES.LIST,
		VISUAL_FLOW_MODULE_TYPES.RANKING,
	]

	const basicModuleResult = allowedTypes.includes(module.type)

	const uiCommandResult =
		module.type === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND &&
		canBeUiCommandUsedInCondition(module) === true

	const proFlowEvaluatorResult = module.type === PROFLOW_TYPES.A_EVALUATOR

	const snippetResult =
		module.type === VISUAL_FLOW_MODULE_TYPES.A_SNIPPET &&
		allowedTypes.includes(module.definition.type)

	const choiceResult =
		(module.type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE &&
			module.definition.isLanguageChoice !== true) ||
		(module.type === VISUAL_FLOW_MODULE_TYPES.A_SNIPPET &&
			module.definition.type === VISUAL_FLOW_MODULE_TYPES.A_CHOICE &&
			module.definition.isLanguageChoice !== true)

	return (
		basicModuleResult === true ||
		choiceResult === true ||
		uiCommandResult === true ||
		snippetResult === true ||
		proFlowEvaluatorResult
	)
}

export const filterModulesForCondition = modules => {
	return modules.filter(module => canBeUsedInCondition(module))
}
