/**
 * Imports
 */
import _ from 'lodash'
import { orderBy as naturalOrderBy } from 'natural-orderby'

import { getLegendColumns } from 'store/reportBuilder'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'

import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'
import { tools } from 'constants/tools'

/**
 * Calculator Functions
 */
export const calculateSlideLegendColumns = (
	reportType,
	legendState,
	slideSegments,
	idsSegments,
	transform,
	slideSettings,
) => {
	if (_.isFunction(transform) === false) {
		throw new Error('calculateSlideLegendColumns: `transform` argument is not a function!')
	}

	const allColumns = getLegendColumns(reportType, slideSegments, idsSegments, slideSettings)

	return transform(allColumns, legendState)
}

export const calculateCentralStatementOptions = studyObjectData => {
	const centralStatementOptions = {}
	Object.entries(studyObjectData).forEach(([idStudyObject, data]) => {
		const optionsFromStatements = data.statements.map(statement => ({
			value: statement.idStatement,
			label: statement.label,
		}))

		const optionsFromGroups = data.statementGroups.map(group => ({
			value: group.idStatementGroup,
			label: group.label,
		}))

		const allOptions = [...optionsFromStatements, ...optionsFromGroups]

		centralStatementOptions[idStudyObject] = naturalOrderBy(allOptions, 'label', 'asc')
	})

	return centralStatementOptions
}

export const calculateCentralStatement = (
	reportType,
	idStudy,
	idCentralStatement,
	studyObjectData,
	blobData,
) => {
	if (
		reportType !== REPORT_TYPES.CONSENSUS ||
		_.isNil(studyObjectData) === true ||
		_.isNil(studyObjectData.statements) === true
	) {
		return null
	}

	const centralStatementFromStatements = studyObjectData.statements.find(
		statement => statement.idStatement === idCentralStatement,
	)

	const data =
		blobData[
			getBlobDataKey(
				idStudy,
				studyObjectData.idStudyObject,
				REPORT_BLOB_TYPES.OEQ_CONSENSUS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (data === undefined) {
		return null
	}

	const centralStatementFromBlob = data.statementSupports[idCentralStatement] ?? {
		letter: '',
		supportStrength: {
			lowerBound: 0,
		},
		color: '#000000',
	}

	const statementTheme = studyObjectData.statementThemes.find(theme =>
		theme.statements.includes(idCentralStatement),
	)

	if (centralStatementFromStatements !== undefined) {
		return {
			value: centralStatementFromStatements.idStatement,
			label: centralStatementFromStatements.label,
			letter: centralStatementFromBlob.letter,
			totalSupportPercent: Math.round(100 * centralStatementFromBlob.supportStrength.lowerBound),
			supportPercent: Math.round(100 * centralStatementFromBlob.supportStrength.lowerBound),
			idStatement: idCentralStatement,
			themeName: statementTheme?.themeName ?? null,
			fill: centralStatementFromBlob.color,
		}
	}

	const centralStatementFromGroups = studyObjectData.statementGroups.find(
		group => group.idStatementGroup === idCentralStatement,
	)

	if (centralStatementFromGroups !== undefined) {
		return {
			value: centralStatementFromGroups.idStatementGroup,
			label: centralStatementFromGroups.label,
			letter: centralStatementFromBlob.letter,
			totalSupportPercent: Math.round(100 * centralStatementFromBlob.supportStrength.lowerBound),
			supportPercent: Math.round(100 * centralStatementFromBlob.supportStrength.lowerBound),
			idStatement: idCentralStatement,
			themeName: statementTheme?.themeName ?? null,
			fill: centralStatementFromBlob.color,
		}
	}

	return null
}
