import { VISUAL_FLOW_INVALID_MODULE_TYPES } from 'constants/studyDesign'
import { matchUrl } from 'components/_formik/validation'

const validateRedirect = module => {
	const validationResult = []

	const hasInvalidUrl = matchUrl(module.definition.redirectUrl)

	if (hasInvalidUrl === true) {
		validationResult.push({
			id: module.definition.id,
			type: VISUAL_FLOW_INVALID_MODULE_TYPES.redirect_invalid_url,
		})
	}

	return validationResult
}

export default validateRedirect
