import _ from 'lodash'

import { translations } from 'languages'

import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import isChoiceOptionNoneOfThese from 'helpers/visualFlowModules/isChoiceOptionNoneOfThese'

import { getDefaultOptionLabel } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getDefaultOptionLabel'
import { getFreetextSettings } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getFreetextSettings'
import { getOptionLabelIdTranslation } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/getOptionLabelIdTranslation'
import { CHOICE_OPTION } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/CHOICE_OPTION.js'
import { getNoneOfTheseImageUrl } from 'routes/_study/StudyDesign/_store/flowModuleDefinitions/helpers/MEDIA_OBJECT'

const mutateSetLanguageIfUndefined = (obj, path, language, value) => {
	const defaultValue = translations[language][value] ?? value

	/**
	 * THIS MUTATES THE obj ARGUMENT
	 */
	if (_.get(obj, `${path}.${language}`) === undefined) {
		_.set(obj, `${path}.${language}`, defaultValue)
	}
}

export const addLanguageToModules = (modules, language, enabledLanguages) => {
	const newModules = {}

	Object.entries(modules).forEach(([idModule, flowModule]) => {
		const newModule = _.cloneDeep(flowModule)
		newModules[idModule] = newModule

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.ALLOCATION) {
			mutateSetLanguageIfUndefined(newModule, 'definition.instructionSettings.text', language, '')
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.A_END_STUDY) {
			mutateSetLanguageIfUndefined(newModule, 'definition.redirectLabel', language, 'submit')
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.REDIRECT) {
			mutateSetLanguageIfUndefined(newModule, 'definition.redirectLabel', language, 'proceed')
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.A_FREE_TEXT) {
			const freetextSettings = getFreetextSettings(newModule.definition.freetextType)

			mutateSetLanguageIfUndefined(
				newModule,
				'definition.inputHint',
				language,
				freetextSettings.inputHintTranslation,
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.placeholder',
				language,
				freetextSettings.placeholderTranslation,
			)
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.A_OEQ) {
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.ideate_placeholder',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.elaborate_intro',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.elaborate_agree_label',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.elaborate_disagree_label',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.elaborate_indifferent_label',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.translations.elaborate_unclear_statement_label',
				language,
				'',
			)
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.HEATMAP) {
			mutateSetLanguageIfUndefined(newModule, 'definition.imageUrl', language, '')
			mutateSetLanguageIfUndefined(newModule, 'definition.thumbnailUrl', language, '')
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.LIST) {
			mutateSetLanguageIfUndefined(newModule, 'definition.optionLabelIdentifiers', language, '')
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.A_MESSAGE) {
			mutateSetLanguageIfUndefined(newModule, 'definition.text', language, 'new_message')
			mutateSetLanguageIfUndefined(newModule, 'definition.media.alt', language, '')
			mutateSetLanguageIfUndefined(newModule, 'definition.media.url', language, '')
			mutateSetLanguageIfUndefined(newModule, 'definition.media.thumbnailUrl', language, '')
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.media.actionButton.label',
				language,
				'proceed',
			)
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.MAXDIFF) {
			const questionKeys = ['bestQuestion', 'worstQuestion']
			const questions = questionKeys.map(key => newModule.definition[key])
			questions.forEach(question => {
				mutateSetLanguageIfUndefined(question, 'definition.text', language, 'new_message')
				mutateSetLanguageIfUndefined(question, 'definition.media.alt', language, '')
				mutateSetLanguageIfUndefined(question, 'definition.media.url', language, '')
				mutateSetLanguageIfUndefined(question, 'definition.media.thumbnailUrl', language, '')
				mutateSetLanguageIfUndefined(
					question,
					'definition.media.actionButton.label',
					language,
					'proceed',
				)
			})
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.MATRIX_CHOICE) {
			mutateSetLanguageIfUndefined(newModule, 'definition.sharedMessage.text', language, '')
			mutateSetLanguageIfUndefined(newModule, 'definition.sharedMessage.media.alt', language, '')
			mutateSetLanguageIfUndefined(newModule, 'definition.sharedMessage.media.url', language, '')
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.sharedMessage.media.thumbnailUrl',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.sharedMessage.media.actionButton.label',
				language,
				'proceed',
			)

			mutateSetLanguageIfUndefined(
				newModule,
				'definition.listSettings.question.text',
				language,
				'matrix_loop_question',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.listSettings.question.media.alt',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.listSettings.question.media.url',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.listSettings.question.media.thumbnailUrl',
				language,
				'',
			)
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.listSettings.question.media.actionButton.label',
				language,
				'proceed',
			)

			newModule.definition.questions.forEach(question => {
				mutateSetLanguageIfUndefined(question, 'text', language, 'question_default')
				mutateSetLanguageIfUndefined(question, 'media.alt', language, '')
				mutateSetLanguageIfUndefined(question, 'media.url', language, '')
				mutateSetLanguageIfUndefined(question, 'media.thumbnailUrl', language, '')
				mutateSetLanguageIfUndefined(question, 'media.actionButton.label', language, 'proceed')
			})
		}

		if (newModule.type === VISUAL_FLOW_MODULE_TYPES.UI_COMMAND) {
			mutateSetLanguageIfUndefined(newModule, 'definition.actionButton.label', language, 'proceed')
		}

		if (newModule.definition.options !== undefined) {
			newModule.definition.options.forEach((option, optionIndex) => {
				const idLabelTranslation =
					newModule.definition.isLanguageChoice === true
						? option.languageCode
						: getOptionLabelIdTranslation(newModule.type, option.isNoneOfThese)

				const labelTranslation = translations[language][idLabelTranslation] ?? idLabelTranslation
				const optionLabel =
					newModule.definition.isLanguageChoice === true || option.isNoneOfThese === true
						? labelTranslation
						: `${labelTranslation} ${optionIndex + 1}`

				mutateSetLanguageIfUndefined(option, 'label', language, optionLabel)
				mutateSetLanguageIfUndefined(option, 'simpleName', language, '')

				if (option.media !== null) {
					const mediaUrl =
						isChoiceOptionNoneOfThese(option) === true ? getNoneOfTheseImageUrl(language) : ''

					mutateSetLanguageIfUndefined(option, 'media.alt', language, '')
					mutateSetLanguageIfUndefined(option, 'media.url', language, mediaUrl)
					mutateSetLanguageIfUndefined(option, 'media.thumbnailUrl', language, mediaUrl)
					mutateSetLanguageIfUndefined(option, 'media.actionButton.label', language, 'proceed')
				}
			})
		}

		if (newModule.definition.dynamicOptionsSettings !== undefined) {
			mutateSetLanguageIfUndefined(
				newModule,
				'definition.dynamicOptionsSettings.optionLabelColumn',
				language,
				null,
			)
		}

		if (newModule.definition.messages !== undefined) {
			newModule.definition.messages.forEach(message => {
				mutateSetLanguageIfUndefined(message, 'definition.text', language, 'question_default')
				mutateSetLanguageIfUndefined(message, 'definition.media.alt', language, '')
				mutateSetLanguageIfUndefined(message, 'definition.media.url', language, '')
				mutateSetLanguageIfUndefined(message, 'definition.media.thumbnailUrl', language, '')
				mutateSetLanguageIfUndefined(
					message,
					'definition.media.actionButton.label',
					language,
					'proceed',
				)
			})
		}

		if (newModule.definition.isLanguageChoice === true) {
			newModule.definition.options.push(
				CHOICE_OPTION(
					newModule.definition.subtype,
					false,
					newModule.definition.options.length + 1,
					getDefaultOptionLabel(language, enabledLanguages, false),
					false,
					enabledLanguages,
					null,
					language,
				),
			)
		}
	})

	return newModules
}
