// ------------------------------------
// Imports
// ------------------------------------
import _ from 'lodash'

import { translateSegmentLabel } from 'helpers/segments/translateSegmentLabel'

import {
	REPORT_TYPES,
	LEGEND_COLUMN_HEADERS,
	LEGEND_COLUMN_TYPES,
	AI_OPEN_END_SUPPORT_TYPE,
} from 'constants/reports'

// ------------------------------------
// Constants
// ------------------------------------

export const LEGEND_KEYS = {
	SORTED: 'sorted',
	FILTERED: 'filtered',
}

export const SLIDE_SETTINGS_KEYS = {
	CROSSTAB_SEGMENTS: 'idsSegment',
}

export const GROUP_LEGEND_BY_OPTIONS = {
	SEGMENT: 'segment',
	DATASET: 'dataset',
}

export const CHART_LEGEND_OPTIONS = [
	{ label: 'Disabled', value: 'LEGEND_DISABLED' },
	{ label: 'Static', value: 'LEGEND_STATIC' },
	{ label: 'Draggable', value: 'LEGEND_DRAGGABLE' },
] // ------------------------------------
// Helpers
// ------------------------------------

export const getSegmentWeightedAverageKey = idSegment => `${idSegment}_weightedAverage`

export const getSegmentTotalSupportPercentageKey = idSegment =>
	`${idSegment}_${LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH}`

export const getSegmentTotalSupportPercentageChartKey = idSegment =>
	`${idSegment}_${LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH}_chart`

export const getSegmentTotalSupportDecimalPercentageKey = idSegment =>
	`${idSegment}_${LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH}_decimal`

export const getHasExpectedSupportCalculatedKey = idSegment =>
	`${idSegment}_hasExpectedSupportCalculated`

export const getSegmentTotalSupportCompletesKey = idSegment => `${idSegment}_totalSupportCompletes`

export const getSegmentTotalSupportLowerBoundKey = idSegment =>
	`${idSegment}_totalSupportLowerBound`

export const getSegmentTotalSupportUpperBoundKey = idSegment =>
	`${idSegment}_totalSupportUpperBound`

export const getSegmentConfidenceIntervalKey = idSegment => `${idSegment}_confidenceInterval`

export const getSegmentConfidenceIntervalDecimalKey = idSegment =>
	`${idSegment}_confidenceInterval_decimal`

export const getSegmentAgreeCountKey = idSegment => `${idSegment}_agreeCount`

export const getSegmentDisagreeCountKey = idSegment => `${idSegment}_disagreeCount`

export const getSegmentIndifferentCountKey = idSegment => `${idSegment}_indifferentCount`

export const getSegmentAverageValueKey = idSegment => `${idSegment}_average`

export const getSegmentOptionRespondentsCountKey = idSegment => `${idSegment}_respondentsCount`

export const findSegment = (idSegment, segments) => segments.find(s => s.idSegment === idSegment)

const getValidExpectedSupportHeader = (segmentLabel, columnLabel, idsSegments) =>
	idsSegments.length <= 1 ? columnLabel : `${columnLabel} - ${segmentLabel}`

const getOpenAnswersColumns = (supportLabel, slideSegments, idsSegments, getKeyFn) => {
	return idsSegments.map((idSegment, i) => {
		const segment = findSegment(idSegment, slideSegments)
		const isValid = _.isNil(segment) === false
		const normalizedSegmentLabel = translateSegmentLabel(segment)

		const validHeader = getValidExpectedSupportHeader(
			normalizedSegmentLabel,
			supportLabel,
			idsSegments,
		)
		const Header = isValid === true ? validHeader : normalizedSegmentLabel

		return {
			Header,
			accessor: getKeyFn(idSegment),
			isValid,
			idSegment,
		}
	})
}

const getValidHeader = (segmentLabel, columnLabel, idsSegments) =>
	idsSegments.length <= 1 ? columnLabel : ` ${columnLabel} - ${segmentLabel}`

const getCrosstabColumns = (supportLabel, slideSegments, idsSegments, getKeyFn) => {
	return idsSegments.map((idSegment, i) => {
		const segment = findSegment(idSegment, slideSegments)
		const isValid = _.isNil(segment) === false
		const normalizedSegmentLabel = translateSegmentLabel(segment)

		const validHeader = getValidHeader(normalizedSegmentLabel, supportLabel, idsSegments)
		const Header = isValid === true ? validHeader : normalizedSegmentLabel

		return {
			Header,
			accessor: getKeyFn(idSegment),
			isValid,
			idSegment,
		}
	})
}

export const getMatrixLegendSubColumnAccessor = (idOption, idSegment, isPercentage = true) => {
	const type = isPercentage === true ? 'percentage' : 'completes'

	return `${LEGEND_COLUMN_TYPES.MATRIX_OPTION}Child_${idOption}_${idSegment}_${type}`
}

const getMatrixColumns = (
	supportLabel,
	slideSegments,
	idsSegments,
	questions,
	options,
	groupByRows,
	groupLegendBySegment,
	language,
) => {
	const getCommonColumn = idSegment => {
		const segment = findSegment(idSegment, slideSegments)
		const isValid = _.isNil(segment) === false
		const normalizedSegmentLabel = translateSegmentLabel(segment)
		const validHeader = getValidHeader(normalizedSegmentLabel, supportLabel, idsSegments)

		return {
			Header: isValid === true ? validHeader : normalizedSegmentLabel,
			accessor: `${LEGEND_COLUMN_TYPES.MATRIX_OPTION}Parent`,
			isValid,
		}
	}

	if (groupLegendBySegment === true) {
		return idsSegments.map(idSegment => {
			const commonColumn = getCommonColumn(idSegment)
			const dataset = groupByRows === true ? options : questions

			return {
				...commonColumn,
				columns: dataset.map(option => ({
					Header: option.label[language],
					accessor: getMatrixLegendSubColumnAccessor(option.id, idSegment),
					isValid: commonColumn.isValid,
					isMultiSegment: idsSegments.length > 1,
				})),
			}
		})
	} else {
		const dataset = groupByRows === true ? options : questions
		return dataset.map(option => {
			return {
				Header: option.label[language],
				accessor: `${LEGEND_COLUMN_TYPES.MATRIX_OPTION}Parent`,
				columns: idsSegments.map(idSegment => {
					return {
						...getCommonColumn(idSegment),
						accessor: getMatrixLegendSubColumnAccessor(option.id, idSegment),
						isMultiSegment: idsSegments.length > 1,
					}
				}),
				isValid: true,
			}
		})
	}
}

export const getMatrixLegendColumns = (
	slideSegments,
	idsSegments,
	questions,
	options,
	groupByRows,
	groupLegendBySegment,
	language,
) => {
	const simple = [
		{
			Header: groupByRows === true ? LEGEND_COLUMN_HEADERS.QUESTION : LEGEND_COLUMN_HEADERS.OPTION,
			accessor: LEGEND_COLUMN_TYPES.MATRIX_QUESTION,
			isValid: true,
		},
	]

	return simple.concat(
		getMatrixColumns(
			LEGEND_COLUMN_HEADERS.SUPPORT,
			slideSegments,
			idsSegments,
			questions,
			options,
			groupByRows,
			groupLegendBySegment,
			language,
		),
	)
}

export const getLegendColumns = (reportType, slideSegments, idsSegments, slideSettings = null) => {
	const isThemeViewActive =
		slideSettings === null ? false : _.get(slideSettings, 'themeViewSettings.isActive', false)

	const simple = [
		{
			Header: LEGEND_COLUMN_HEADERS.LETTER,
			accessor: LEGEND_COLUMN_TYPES.LETTER,
			isValid: true,
		},
		{
			Header:
				isThemeViewActive === true ? LEGEND_COLUMN_HEADERS.THEME : LEGEND_COLUMN_HEADERS.ANSWER,
			accessor: LEGEND_COLUMN_TYPES.ANSWER,
			isValid: true,
		},
	]

	switch (reportType) {
		case REPORT_TYPES.OPEN_ANSWERS:
			const supportLabel =
				slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT
					? LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT
					: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH

			const openAnswersSpecific = getOpenAnswersColumns(
				supportLabel,
				slideSegments,
				idsSegments,
				getSegmentTotalSupportPercentageKey,
			)

			if (isThemeViewActive === true) {
				return simple.concat(
					{
						Header: LEGEND_COLUMN_HEADERS.ANSWERS_COUNT,
						accessor: LEGEND_COLUMN_TYPES.ANSWERS_COUNT,
						isValid: true,
					},
					openAnswersSpecific,
				)
			}

			return simple.concat(openAnswersSpecific, {
				Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
				accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
				isValid: true,
			})

		case REPORT_TYPES.SEGMENTATION_TYPING_TOOL:
			return [
				{
					Header: LEGEND_COLUMN_HEADERS.LETTER,
					accessor: LEGEND_COLUMN_TYPES.LETTER,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.SEGMENT_NAME,
					accessor: LEGEND_COLUMN_TYPES.ANSWER,
					isValid: true,
				},
				getCrosstabColumns(
					LEGEND_COLUMN_HEADERS.SUPPORT,
					slideSegments,
					idsSegments,
					getSegmentTotalSupportPercentageKey,
				),
			]
		case REPORT_TYPES.CHOICE_ANSWERS:
		case REPORT_TYPES.MAXDIFF:
			return simple.concat(
				getCrosstabColumns(
					LEGEND_COLUMN_HEADERS.SUPPORT,
					slideSegments,
					idsSegments,
					getSegmentTotalSupportPercentageKey,
				),
				{
					Header: LEGEND_COLUMN_HEADERS.OPTION_CODE,
					accessor: LEGEND_COLUMN_TYPES.OPTION_CODE,
					isValid: true,
				},
			)
		case REPORT_TYPES.ALLOCATION_ANSWERS:
		case REPORT_TYPES.RANKING_ANSWERS:
			return simple.concat(
				getCrosstabColumns(
					LEGEND_COLUMN_HEADERS.AVERAGE_VALUE,
					slideSegments,
					idsSegments,
					getSegmentAverageValueKey,
				),
				{
					Header: LEGEND_COLUMN_HEADERS.OPTION_CODE,
					accessor: LEGEND_COLUMN_TYPES.OPTION_CODE,
					isValid: true,
				},
			)
		case REPORT_TYPES.IDEACLOUD:
			const ideaCloudSpecific = {
				Header: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH,
				accessor: LEGEND_COLUMN_TYPES.SUPPORT,
				isValid: true,
			}

			if (isThemeViewActive === true) {
				return simple.concat(
					{
						Header: LEGEND_COLUMN_HEADERS.ANSWERS_COUNT,
						accessor: LEGEND_COLUMN_TYPES.ANSWERS_COUNT,
						isValid: true,
					},
					ideaCloudSpecific,
				)
			}

			return simple.concat(ideaCloudSpecific, {
				Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
				accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
				isValid: true,
			})
		case REPORT_TYPES.FREE_TEXT:
			return [
				{
					Header: LEGEND_COLUMN_HEADERS.ANSWER,
					accessor: LEGEND_COLUMN_TYPES.ANSWER,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.RESPONDENTS_COUNT,
					accessor: LEGEND_COLUMN_TYPES.VALUE,
					isValid: true,
				},
			]
		case REPORT_TYPES.FREE_TEXT_HISTOGRAM:
			return [
				{
					Header: LEGEND_COLUMN_HEADERS.ANSWER,
					accessor: LEGEND_COLUMN_TYPES.ANSWER,
					isValid: true,
				},
				...getCrosstabColumns(
					LEGEND_COLUMN_HEADERS.RESPONDENTS_COUNT,
					slideSegments,
					idsSegments,
					getSegmentOptionRespondentsCountKey,
				),
			]
		case REPORT_TYPES.INTELLISEGMENT:
			const isFourSegment = slideSegments.length === 4
			const withoutSegments = simple.concat(
				{
					Header: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH,
					accessor: LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.TOTAL_SUPPORT,
					accessor: LEGEND_COLUMN_TYPES.TOTAL_SUPPORT,
					isValid: true,
				},
			)

			if (isFourSegment === false) {
				return withoutSegments.concat([
					{
						Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
							slideSegments[0],
						)}`,
						accessor: LEGEND_COLUMN_TYPES.TOP_SUPPORT,
						isValid: true,
					},
					{
						Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
							slideSegments[1],
						)}`,
						accessor: LEGEND_COLUMN_TYPES.RIGHT_SUPPORT,
						isValid: true,
					},
					{
						Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
						accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
						isValid: true,
					},
				])
			}

			return withoutSegments.concat([
				{
					Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
						slideSegments[0],
					)}`,
					accessor: LEGEND_COLUMN_TYPES.TOP_SUPPORT,
					isValid: true,
				},
				{
					Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
						slideSegments[2],
					)}`,
					accessor: LEGEND_COLUMN_TYPES.BOTTOM_SUPPORT,
					isValid: true,
				},
				{
					Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
						slideSegments[3],
					)}`,
					accessor: LEGEND_COLUMN_TYPES.LEFT_SUPPORT,
					isValid: true,
				},
				{
					Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
						slideSegments[1],
					)}`,
					accessor: LEGEND_COLUMN_TYPES.RIGHT_SUPPORT,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
					accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
					isValid: true,
				},
			])
		case REPORT_TYPES.CONSENSUS:
			return simple.concat([
				{
					Header: LEGEND_COLUMN_HEADERS.CORRELATION,
					accessor: LEGEND_COLUMN_TYPES.CORRELATION,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH,
					accessor: LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
					accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
					isValid: true,
				},
			])
		case REPORT_TYPES.IDEACLUSTER:
			return simple.concat([
				{
					Header: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH,
					accessor: LEGEND_COLUMN_TYPES.SUPPORT_STRENGTH,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.TOTAL_SUPPORT,
					accessor: LEGEND_COLUMN_TYPES.TOTAL_SUPPORT,
					isValid: true,
				},
				{
					Header: `${LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT} - ${translateSegmentLabel(
						slideSegments[0],
					)} `,
					accessor: LEGEND_COLUMN_TYPES.SUPPORT,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.STATEMENT_THEME_NAME,
					accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
					isValid: true,
				},
			])
		case REPORT_TYPES.SUNBURST:
			return [
				{
					Header: LEGEND_COLUMN_HEADERS.THEME_NAME,
					accessor: LEGEND_COLUMN_TYPES.STATEMENT_THEME_NAME,
					isValid: true,
				},
				{
					Header:
						slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT
							? LEGEND_COLUMN_HEADERS.EXPECTED_SUPPORT
							: LEGEND_COLUMN_HEADERS.SUPPORT_STRENGTH,
					accessor: LEGEND_COLUMN_TYPES.SUNBURST_SUPPORT,
					isValid: true,
				},
				{
					Header: LEGEND_COLUMN_HEADERS.RESPONSES_COUNT,
					accessor: LEGEND_COLUMN_TYPES.RESPONSES_COUNT,
					isValid: true,
				},
			]
		default:
			throw new Error(`Report builder: Slide type: ${reportType} does not exist!`)
	}
}

const flatLegendColumns = columns =>
	columns
		.map(column =>
			_.isNil(column.columns) === false
				? [
						...column.columns.map(subColumn => ({
							...subColumn,
							Header:
								subColumn.isMultiSegment === true
									? column.Header + ' - ' + subColumn.Header
									: subColumn.Header,
						})),
				  ]
				: column,
		)
		.flat()

export const transformSortableColumnsIntoOptions = (all, { sorted }) =>
	flatLegendColumns(all).map(c => {
		const sortedColumn = sorted.find(sc => sc.id === c.accessor) || {}
		return { label: c.Header, value: c.accessor, desc: sortedColumn.desc || false }
	})

export const transformFilterableColumnsIntoOptions = (all, { filtered }) =>
	flatLegendColumns(all).map(c => {
		const filteredColumn = filtered.find(fc => fc.id === c.accessor) || {}
		return { label: c.Header, value: c.accessor, input: filteredColumn.value || '' }
	})
