import _ from 'lodash'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { REPORT_TYPES, REPORT_BLOB_TYPES } from 'constants/reports'
import { getSegmentOptionRespondentsCountKey } from 'store/reportBuilder'

const formatValue = value => {
	if (typeof value === 'number') {
		return value
	}

	if (typeof value === 'string') {
		return Number(value.replace(',', '.'))
	}

	return value
}

const getLimitValue = (key, slideSettings, allSegmentsData) => {
	const valueFromSettings = slideSettings[key]

	if (valueFromSettings !== '') {
		return Number(valueFromSettings)
	}

	const fn = key === 'max' ? Math.max : Math.min

	return fn(
		...allSegmentsData.map(segmentData =>
			fn(
				...segmentData.answers
					.map(({ answer }) => formatValue(answer))
					.filter(formattedAnswer => isNaN(formattedAnswer) === false),
			),
		),
	)
}

export const calculateHistogramData = (idStudy, reportType, slideSettings, blobData) => {
	if (reportType !== REPORT_TYPES.FREE_TEXT_HISTOGRAM) {
		return []
	}

	if (slideSettings === undefined) {
		return []
	}

	const { idStudyObject, idsSegments } = slideSettings

	const bucketCount = Number(slideSettings.buckets)

	// invalid settings, show empty chart
	if (isNaN(bucketCount) === true || bucketCount < 1) {
		return []
	}

	// invalid settings, show empty chart
	if (slideSettings.max !== '' && isNaN(Number(slideSettings.max)) === true) {
		return []
	}

	// invalid settings, show empty chart
	if (slideSettings.min !== '' && isNaN(Number(slideSettings.min)) === true) {
		return []
	}

	const dataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.FREE_TEXT_STATISTICS, idSegment)
			],
	)

	if (hasBlobData(dataArray, 'answers') === false) {
		return []
	}

	const max = getLimitValue('max', slideSettings, dataArray)
	const min = getLimitValue('min', slideSettings, dataArray)

	// invalid settings, show empty chart
	if (max < min) {
		return []
	}

	const range = max - min
	const step = range / bucketCount

	const chartBuckets = []

	for (let i = 0; i < bucketCount; i++) {
		const from = min + i * step
		const to = min + (i + 1) * step

		const name = `${_.round(from, 2)} - ${_.round(to, 2)}`

		const bucket = {
			from,
			to,
			name,
			label: name,
			id: name,
			isExcluded: slideSettings.excludedAnswers.includes(name),
		}

		idsSegments.forEach(idSegment => {
			bucket[getSegmentOptionRespondentsCountKey(idSegment)] = 0
		})

		chartBuckets.push(bucket)
	}

	idsSegments.forEach(idSegment => {
		const segmentData = dataArray.find(data => data.idSegment === idSegment)

		segmentData.answers.forEach(({ answer }) => {
			const value = formatValue(answer)

			if (isNaN(value) === true) {
				return
			}

			if (value < min || value > max) {
				return
			}

			const bucket = chartBuckets.find(bucket => value >= bucket.from && value <= bucket.to)

			const segmentValueKey = getSegmentOptionRespondentsCountKey(idSegment)

			bucket[segmentValueKey] = bucket[segmentValueKey] + 1
		})
	})

	const bucketsWithExportData = chartBuckets.map(bucket => ({
		...bucket,
		toExport: () => [
			`From ${_.round(bucket.from, 2)} to ${_.round(bucket.to, 2)}`,
			...idsSegments.map(idSegment => bucket[getSegmentOptionRespondentsCountKey(idSegment)]),
		],
	}))

	return bucketsWithExportData
}
