import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classnames from 'classnames'
import { Field } from 'formik'
import { useIntl } from 'react-intl'

import Input from 'components/_formik/_base/Input'
import Label from 'components/_formik/_base/Label'

import Icon from 'components/_scaffolding/Icon'
import Tooltip from 'components/_scaffolding/Tooltip'

import classes from './UrlInput.module.scss'

const isHttps = url => url.includes('https://')

const UrlInput = ({ disabled, fieldName, label, placeholder, value }) => {
	const intl = useIntl()

	return (
		<Fragment>
			<Label
				label={label}
				secondaryLabel={
					<Icon
						name={Icon.NAMES.QUESTION}
						data-tip={intl.formatMessage({ id: 'url_validation_skip' })}
						data-for="url-validation-tooltip"
					/>
				}
			/>
			<div
				className={classnames(classes['url__holder'], {
					[classes['url__holder--https']]: isHttps(value) === true,
					[classes['url__holder--http']]: isHttps(value) === false,
				})}
			>
				<Field
					name={fieldName}
					component={Input}
					className={classes.url__input}
					disabled={disabled}
					placeholder={placeholder}
					validate={{ validUrl: true }}
				/>
			</div>
			<Tooltip className={classes.url__tooltip} id="url-validation-tooltip" />
		</Fragment>
	)
}

UrlInput.propTypes = {
	disabled: PropTypes.bool.isRequired,
	fieldName: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
}

export default UrlInput
