export const OBJECT_TYPES = {
	ACTION_BUTTON: 'A_ACTION_BUTTON',
	ALLOCATION: 'ALLOCATION',
	CHOICE: 'A_CHOICE',
	END_STUDY: 'A_END_STUDY',
	EVALUATOR: 'A_EVALUATOR',
	EVALUATOR_IF: 'EVALUATOR_IF',
	EVALUATOR_SET: 'EVALUATOR_SET',
	FREE_TEXT: 'A_FREE_TEXT',
	HEATMAP: 'HEATMAP',
	LIST: 'LIST',
	MAXDIFF: 'MAXDIFF',
	MEDIA_OBJECT: 'A_MEDIA_OBJECT',
	MESSAGE: 'A_MESSAGE',
	OEQ: 'A_OEQ',
	RANKING: 'RANKING',
	REDIRECT: 'REDIRECT',
	RESEARCH_LABELING: 'RESEARCH_LABELING',
	UI_COMMAND: 'UI_COMMAND',
}

export default OBJECT_TYPES
