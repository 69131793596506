import { CHOICE_SUBTYPE, VISUAL_FLOW_WELCOME_SCREEN } from 'constants/studyDesign'

import ALLOCATION from './ALLOCATION'
import A_CHOICE from './CHOICE'
import A_END_STUDY from './END_STUDY'
import A_FREE_TEXT from './FREE_TEXT'
import A_MESSAGE from './MESSAGE'
import A_OEQ from './OPENQUESTION'
import A_SNIPPET from './SNIPPET'
import HEATMAP from './HEATMAP'
import LIST from './LIST'
import MATRIX_CHOICE from './MATRIX_CHOICE'
import MAXDIFF from './MAXDIFF'
import RANKING from './RANKING'
import REDIRECT from './REDIRECT'
import SET_VARIABLE from './SET_VARIABLE'
import UI_COMMAND from './UI_COMMAND'

export const MODULE_DEFINITIONS = {
	ALLOCATION,
	A_CHOICE: A_CHOICE(CHOICE_SUBTYPE.CHECKBOX),
	A_END_STUDY,
	A_FREE_TEXT,
	HEATMAP,
	A_IMAGE_CHOICE: A_CHOICE(CHOICE_SUBTYPE.IMAGE),
	A_MESSAGE,
	A_OEQ,
	A_SNIPPET,
	LIST,
	MAXDIFF,
	RANKING,
	REDIRECT,
	SET_VARIABLE,
	UI_COMMAND,
	// welcome screen is not a solver module
	WELCOME_SCREEN: {
		type: VISUAL_FLOW_WELCOME_SCREEN,
		style: VISUAL_FLOW_WELCOME_SCREEN,
	},
	// placeholder is not a solver module
	PLACEHOLDER: { type: 'placeholder' },
	MATRIX_CHOICE: MATRIX_CHOICE(CHOICE_SUBTYPE.CHECKBOX),
	MATRIX_IMAGE_CHOICE: MATRIX_CHOICE(CHOICE_SUBTYPE.IMAGE),
}
