import _ from 'lodash'

import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getThemeParams } from 'helpers/reportBuilder/getThemeParams'
import { getStatementThemeName } from 'helpers/statementThemes'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getStatementsToRender } from 'helpers/reports/getStatementsToRender'
import { filterOutEmptyThemes } from 'helpers/reports/filterOutEmptyThemes'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportPercentageChartKey,
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportLowerBoundKey,
	getSegmentTotalSupportUpperBoundKey,
	getSegmentConfidenceIntervalKey,
	getSegmentAgreeCountKey,
	getSegmentDisagreeCountKey,
	getSegmentIndifferentCountKey,
	getSegmentTotalSupportDecimalPercentageKey,
	getSegmentConfidenceIntervalDecimalKey,
	getHasExpectedSupportCalculatedKey,
} from 'store/reportBuilder'
import {
	REPORT_TYPES,
	REPORT_BLOB_TYPES,
	SUPPORT_KEYS,
	AI_OPEN_END_SUPPORT_TYPE,
} from 'constants/reports'
import { COLORS } from 'constants/colors'

const getPercentAndCompletes = (ideaOrTheme, slideSettings, idsSegments) => {
	if (slideSettings.themeViewSettings.isActive === true) {
		return _.flatten(
			idsSegments.map(idSegment => {
				const percent = ideaOrTheme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]

				return [percent]
			}),
		)
	}

	if (slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT) {
		return _.flatten(
			idsSegments.map(idSegment => {
				const percent = ideaOrTheme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
				const confidence = ideaOrTheme[getSegmentConfidenceIntervalDecimalKey(idSegment)]
				const agreeCount = ideaOrTheme[getSegmentAgreeCountKey(idSegment)]
				const disagreeCount = ideaOrTheme[getSegmentDisagreeCountKey(idSegment)]
				const indifferentCount = ideaOrTheme[getSegmentIndifferentCountKey(idSegment)]

				return [agreeCount, indifferentCount, disagreeCount, percent, confidence]
			}),
		)
	}

	return _.flatten(
		idsSegments.map(idSegment => {
			const percent = ideaOrTheme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
			const agreeCount = ideaOrTheme[getSegmentAgreeCountKey(idSegment)]
			const disagreeCount = ideaOrTheme[getSegmentDisagreeCountKey(idSegment)]
			const indifferentCount = ideaOrTheme[getSegmentIndifferentCountKey(idSegment)]

			return [agreeCount, indifferentCount, disagreeCount, percent]
		}),
	)
}

/**
 * Munge Functions
 */
const mungeData = (ideasAndThemes, idsSegments, slideSettings) => {
	return ideasAndThemes.map(ideaOrTheme => {
		const isTheme = ideaOrTheme.idStatement === undefined

		const percentAndCompletes = getPercentAndCompletes(ideaOrTheme, slideSettings, idsSegments)

		const themeName = getStatementThemeName(ideaOrTheme, true)

		const name = isTheme === true ? themeName : ideaOrTheme.label

		const dataToExport =
			slideSettings.themeViewSettings.isActive === true
				? [ideaOrTheme.letter, name, ideaOrTheme.statements?.length || '', ...percentAndCompletes]
				: [ideaOrTheme.letter, name, ...percentAndCompletes, themeName || '']

		const id = ideaOrTheme.idStatement || ideaOrTheme.idStatementTheme

		const customColor = _.get(slideSettings, `customColors.${id}`, null)

		const formattedIdea = {
			..._.pick(
				ideaOrTheme,
				_.flatMap(idsSegments, idSegment =>
					isTheme === true
						? [
								getSegmentTotalSupportPercentageKey(idSegment),
								getSegmentTotalSupportPercentageChartKey(idSegment),
								getSegmentTotalSupportCompletesKey(idSegment),
								getSegmentTotalSupportLowerBoundKey(idSegment),
								getSegmentTotalSupportUpperBoundKey(idSegment),
								getHasExpectedSupportCalculatedKey(idSegment),
						  ]
						: [
								getSegmentTotalSupportPercentageKey(idSegment),
								getSegmentTotalSupportPercentageChartKey(idSegment),
								getSegmentTotalSupportCompletesKey(idSegment),
								getSegmentTotalSupportLowerBoundKey(idSegment),
								getSegmentTotalSupportUpperBoundKey(idSegment),
								getSegmentConfidenceIntervalKey(idSegment),
								getSegmentAgreeCountKey(idSegment),
								getSegmentDisagreeCountKey(idSegment),
								getSegmentIndifferentCountKey(idSegment),
								getHasExpectedSupportCalculatedKey(idSegment),
						  ],
				),
			),
			answersCount: isTheme === true ? ideaOrTheme.statements.length : null,
			name,
			fill: customColor ?? ideaOrTheme.color,
			hasCustomColor: customColor !== null,
			id,
			letter: ideaOrTheme.letter,
			shown: true,
			unit: '%',
			toExport: () => dataToExport,
			aiDescription: {
				label: name,
			},
			// NOTE: "parent theme" data
			themeColor: isTheme === true ? null : ideaOrTheme.themeColor,
			hasCustomThemeColor: isTheme === true ? false : ideaOrTheme.hasCustomThemeColor,
			idParentTheme: isTheme === true ? null : ideaOrTheme.idParentTheme,
			themeName: isTheme === true ? null : themeName,
		}

		idsSegments.forEach(idSegment => {
			formattedIdea.aiDescription[idSegment] =
				ideaOrTheme[getSegmentTotalSupportPercentageKey(idSegment)]
		})

		return formattedIdea
	})
}

const calculateIdeasAndThemes = (
	idStudy,
	blobData,
	statementsTotalSegmentData,
	themesTotalSegmentData,
	studyObjectData,
	slideSettings,
	chartColorSettings,
) => {
	if (slideSettings === undefined) {
		return []
	}

	if (studyObjectData === undefined) {
		return []
	}

	const { idStudyObject, idsSegments, themeViewSettings, supportType } = slideSettings

	const statementsToRender = getStatementsToRender(
		statementsTotalSegmentData,
		studyObjectData,
		slideSettings,
		chartColorSettings,
	)

	const { statementThemes } = studyObjectData

	const themesToRender =
		themeViewSettings.isActive === true ? filterOutEmptyThemes(statementThemes) : []

	const statements = statementsToRender.map(statement => {
		const themeParams = getThemeParams(
			statement,
			statement,
			statementThemes,
			statementsTotalSegmentData.statementSupports,
			slideSettings.useThemes,
			themeViewSettings.isActive,
		)

		const idea = {
			...statement,
			color: themeParams.color,
			themeColor: themeParams.color,
			themeName: themeParams.name,
			idParentTheme: themeParams.idStatementTheme,
			originalLanguageThemeName: themeParams.originalLanguageThemeName,
			hasCustomThemeColor: themeParams.hasCustomThemeColor,
		}

		idea.color = themeViewSettings.isActive === true ? COLORS.REPORT_BUILDER_NO_THEME : idea.color

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, idSegment)
				]

			const segmentStatementData = segmentData.statementSupports.find(
				segmentStatement => segmentStatement.idStatement === statement.idStatement,
			)

			const elaborationCounters = segmentStatementData.elaborationCounters ?? {}

			const agreeCount = elaborationCounters.agreeCount
			const disagreeCount = elaborationCounters.disagreeCount
			const indifferentCount = elaborationCounters.indifferentCount

			idea[getSegmentAgreeCountKey(idSegment)] = agreeCount
			idea[getSegmentDisagreeCountKey(idSegment)] = disagreeCount
			idea[getSegmentIndifferentCountKey(idSegment)] = indifferentCount

			idea[getSegmentTotalSupportCompletesKey(idSegment)] = Number(
				segmentStatementData.absoluteSupport,
			)

			if (supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT) {
				const hasEvaluations = agreeCount + disagreeCount + indifferentCount > 0

				if (hasEvaluations === false) {
					idea[getHasExpectedSupportCalculatedKey(idSegment)] = false

					idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = null
					idea[getSegmentTotalSupportPercentageKey(idSegment)] = 0
					idea[getSegmentTotalSupportPercentageChartKey(idSegment)] = null
					idea[getSegmentConfidenceIntervalKey(idSegment)] = null

					idea[getSegmentTotalSupportLowerBoundKey(idSegment)] = null
					idea[getSegmentTotalSupportUpperBoundKey(idSegment)] = null
				} else {
					idea[getHasExpectedSupportCalculatedKey(idSegment)] = true

					const expectedSupport = hasEvaluations === true ? segmentStatementData.expectedSupport : 0
					const percentage = Math.round(expectedSupport * 100)
					const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(expectedSupport)

					idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
					idea[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
					idea[getSegmentTotalSupportPercentageChartKey(idSegment)] = Number(percentage)

					const lowerBound = Math.round(
						segmentStatementData.expectedSupportErrorMargin.lowerBound * 100,
					)
					const upperBound = Math.round(
						segmentStatementData.expectedSupportErrorMargin.upperBound * 100,
					)

					const confidenceInterval =
						segmentStatementData.expectedSupport -
						segmentStatementData.confidenceInterval.lowerBound
					const confidence = Math.round(confidenceInterval * 100)
					const confidenceDecimal = getDecimalPointNumberFromLeadingZeroNumber(confidenceInterval)

					idea[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
					idea[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
					idea[getSegmentConfidenceIntervalDecimalKey(idSegment)] = Number(confidenceDecimal)
					idea[getSegmentConfidenceIntervalKey(idSegment)] = Number(confidence)
				}
			} else if (supportType === AI_OPEN_END_SUPPORT_TYPE.SUPPORT_STRENGTH) {
				const percentage = Math.round(segmentStatementData.supportStrength.lowerBound * 100)
				const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
					segmentStatementData.supportStrength.lowerBound,
				)

				idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
				idea[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
				idea[getSegmentTotalSupportPercentageChartKey(idSegment)] = Number(percentage)
			} else {
				throw new Error(`unknown support type in AI Open-End report: ${supportType}`)
			}
		})

		return idea
	})

	const themes = themesToRender.map(themeDescription => {
		const themeSupport = themesTotalSegmentData.themeSupport[themeDescription.idStatementTheme]

		const theme = {
			...themeDescription,
			...themeSupport,
		}

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idSegment)
				]

			const segmentThemeData = segmentData.themeSupport[theme.idStatementTheme]

			if (supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT) {
				// NOTE: consistency is null if we have 0 theme evaluations
				// NOTE: consistency is 0 if we have 0 valid theme evaluations (there can be conflicts that are not counted)
				const consistency = segmentThemeData.consistency
				const hasEvaluations = consistency !== null && consistency !== 0

				if (hasEvaluations === false) {
					theme[getHasExpectedSupportCalculatedKey(idSegment)] = false

					theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = null
					theme[getSegmentTotalSupportPercentageKey(idSegment)] = 0
					theme[getSegmentTotalSupportPercentageChartKey(idSegment)] = null

					theme[getSegmentTotalSupportLowerBoundKey(idSegment)] = null
					theme[getSegmentTotalSupportUpperBoundKey(idSegment)] = null
				} else {
					theme[getHasExpectedSupportCalculatedKey(idSegment)] = true

					const expectedSupport = hasEvaluations === true ? segmentThemeData.expectedSupport : 0

					const percentage = Math.round(expectedSupport * 100)
					const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(expectedSupport)

					theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
					theme[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
					theme[getSegmentTotalSupportPercentageChartKey(idSegment)] = Number(percentage)

					const lowerBound = Math.round(segmentThemeData.supportStrength.lowerBound * 100)
					const upperBound = Math.round(segmentThemeData.supportStrength.upperBound * 100)

					theme[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
					theme[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
				}
			} else if (supportType === AI_OPEN_END_SUPPORT_TYPE.SUPPORT_STRENGTH) {
				const percentage = Math.round(segmentThemeData.supportStrength.lowerBound * 100)
				const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
					segmentThemeData.supportStrength.lowerBound,
				)

				theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
				theme[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
				theme[getSegmentTotalSupportPercentageChartKey(idSegment)] = Number(percentage)
			} else {
				throw new Error(`unknown support type in AI Open-End report: ${supportType}`)
			}
		})

		return theme
	})

	return [...themes, ...statements]
}

/**
 * Derivators
 */
export const calculateLegendIdeasAndThemes = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	blobData,
	chartColorSettings,
	isReportBuilder,
) => {
	if (reportType !== REPORT_TYPES.OPEN_ANSWERS) {
		return []
	}

	if (studyObjectData === undefined) {
		return []
	}

	if (slideSettings === undefined) return []
	const { idStudyObject, idsSegments, themeViewSettings } = slideSettings

	const statementsDataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, idSegment)
			],
	)
	const statementsTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const themesDataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idSegment)
			],
	)
	const themesTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (
		hasBlobData(
			[...statementsDataArray, statementsTotalSegmentData],
			SUPPORT_KEYS.STATEMENT_SUPPORTS,
		) === false
	) {
		return []
	}

	if (
		themeViewSettings.isActive === true &&
		hasBlobData([...themesDataArray, themesTotalSegmentData], SUPPORT_KEYS.THEME_SUPPORT) === false
	) {
		return []
	}

	const ideas = calculateIdeasAndThemes(
		idStudy,
		blobData,
		statementsTotalSegmentData,
		themesTotalSegmentData,
		studyObjectData,
		slideSettings,
		chartColorSettings,
		isReportBuilder,
	)

	return mungeData(ideas, idsSegments, slideSettings)
}
