import _ from 'lodash'

import { tools } from 'constants/tools'
import { COLORS } from 'constants/colors'
import {
	AI_OPEN_END_SUPPORT_TYPE,
	REPORT_TYPES,
	REPORT_BLOB_TYPES,
	SUPPORT_KEYS,
	ID_UNTHEMED_ANSWERS,
} from 'constants/reports'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getStatementsWithoutThemes } from 'helpers/reports/getStatementsWithoutThemes'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { makeStatementsObject } from 'helpers/makeStatementsObject'

const getAnswerWithResponses = (
	statementObject,
	statementSupportBlob,
	sunburstBlob,
	slideSettings,
) => {
	const { idStatement, groupedStatements, label } = statementObject

	const responses = []

	if (_.isNil(sunburstBlob) === false) {
		const answerFromBlob = sunburstBlob.answers[idStatement]

		if (_.isNil(answerFromBlob) === true) {
			throw new Error(
				`Sunburst Blob data don't match with study object data. Missing answers for ${idStatement}. Try triggering (base) recalc on study.`,
			)
		}

		responses.push(...answerFromBlob.responses)
	}

	const statementSupportObject = statementSupportBlob.statementSupports.find(
		statementSupport => statementSupport.idStatement === idStatement,
	)

	const elaborationCounters = statementSupportObject?.elaborationCounters ?? {}

	const agreeCount = elaborationCounters.agreeCount
	const disagreeCount = elaborationCounters.disagreeCount
	const indifferentCount = elaborationCounters.indifferentCount

	const hasEvaluations = agreeCount + disagreeCount + indifferentCount > 0

	const expectedSupport = hasEvaluations === true ? statementSupportObject?.expectedSupport : null

	const supportStrength = statementSupportObject?.supportStrength?.lowerBound

	const sunpportNumber =
		slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT
			? expectedSupport
			: supportStrength

	const support = sunpportNumber ?? '-'

	const confidenceInterval =
		statementSupportObject !== undefined &&
		slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT &&
		hasEvaluations === true
			? _.round(
					statementSupportObject.expectedSupport -
						statementSupportObject.supportStrength.lowerBound,
					2,
			  )
			: null

	return {
		idStatement,
		label,
		responses,
		responsesNumber: responses.reduce((acc, response) => acc + response.count, 0),
		support,
		isGroupedAnswer: groupedStatements !== null,
		confidenceInterval,
	}
}

const calculateSunburstIdeas = (
	statementSupportBlob,
	themeSupportBlob,
	sunburstBlob,
	studyObjectData,
	slideSettings,
) => {
	if (studyObjectData === undefined) {
		return []
	}

	const validStatements = studyObjectData.statements.filter(
		statement => statement.isInvalid === false,
	)

	const { customColors } = slideSettings

	const statementsObject = makeStatementsObject(validStatements, studyObjectData.statementGroups)

	const themedAnswers = studyObjectData.statementThemes.map(theme => {
		const { color, idStatementTheme, statements: themeStatements } = theme
		const themeName = getStatementThemeName(theme, true)

		const customColor = _.get(customColors, idStatementTheme, null)

		const answers = themeStatements
			.filter(idStatement => statementsObject[idStatement] !== undefined)
			.map(idStatement =>
				getAnswerWithResponses(
					statementsObject[idStatement],
					statementSupportBlob,
					sunburstBlob,
					slideSettings,
				),
			)

		const themeSupportData = themeSupportBlob.themeSupport[idStatementTheme]

		const consistency = themeSupportData?.consistency ?? null
		const hasEvaluations = consistency !== null && consistency !== 0

		const expectedSupport = hasEvaluations === true ? themeSupportData?.expectedSupport : null

		const supportStrength = themeSupportData?.supportStrength?.lowerBound

		const themeSupportNumber =
			slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT
				? expectedSupport
				: supportStrength

		const confidenceInterval =
			themeSupportData !== undefined &&
			slideSettings.supportType === AI_OPEN_END_SUPPORT_TYPE.EXPECTED_SUPPORT &&
			hasEvaluations === true
				? _.round(expectedSupport - supportStrength, 2)
				: null

		return {
			answers,
			responsesNumber: answers.reduce((acc, answer) => acc + answer.responsesNumber, 0),
			color: customColor ?? color,
			hasCustomColor: customColor !== null,
			idStatementTheme,
			support: themeSupportNumber ?? '-',
			themeName,
			confidenceInterval,
		}
	})

	// add unthemed answers
	const statementsWithoutThemes = getStatementsWithoutThemes(
		validStatements,
		studyObjectData.statementGroups,
		studyObjectData.statementThemes,
	)

	const customUnthemedColor = _.get(customColors, ID_UNTHEMED_ANSWERS, null)

	const unthemedAnswers = statementsWithoutThemes.map(statement =>
		getAnswerWithResponses(
			statementsObject[statement.idStatement],
			statementSupportBlob,
			sunburstBlob,
			slideSettings,
		),
	)

	const allIdeas = [
		...themedAnswers,
		{
			answers: unthemedAnswers,
			responsesNumber: unthemedAnswers.reduce((acc, answer) => acc + answer.responsesNumber, 0),
			color: customUnthemedColor ?? COLORS.GREY_90,
			hasCustomColor: customUnthemedColor !== null,
			idStatementTheme: ID_UNTHEMED_ANSWERS,
			support: null,
			confidenceInterval: null,
			themeName: null,
		},
	]

	return allIdeas
}

/**
 * Derivators
 */
export const calculateLegendIdeas = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	_legendState,
	blobData,
) => {
	if (reportType !== REPORT_TYPES.SUNBURST) {
		return []
	}

	if (slideSettings === undefined) {
		return []
	}

	const { idStudyObject, idsSegments } = slideSettings

	const totalSegmentStatementsData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const totalSegmentThemesData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const chosenSegmentStatementsData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				idsSegments[0],
			)
		]

	const chosenSegmentsThemesData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idsSegments[0])
		]

	if (
		hasBlobData([totalSegmentStatementsData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false ||
		hasBlobData([totalSegmentThemesData], SUPPORT_KEYS.THEME_SUPPORT) === false ||
		hasBlobData([chosenSegmentStatementsData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false ||
		hasBlobData([chosenSegmentsThemesData], SUPPORT_KEYS.THEME_SUPPORT) === false
	) {
		return []
	}

	const sunburstBlobData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.SUNBURST, tools.TOTAL_SEGMENT_UUID)
		]

	const sunburstData =
		hasBlobData([sunburstBlobData], 'answers') === false ? null : sunburstBlobData

	const ideas = calculateSunburstIdeas(
		chosenSegmentStatementsData,
		chosenSegmentsThemesData,
		sunburstData,
		studyObjectData,
		slideSettings,
	)

	return ideas
}
